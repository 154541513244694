import React from "react";
import PropTypes from "prop-types";

import Menu from "./Menu";
import Footer from "./Footer";
import Exitup from "./Exitup/ExitupComponent";

import { ParallaxProvider } from 'react-scroll-parallax';

import "../styles/main.scss";
import "../../node_modules/animate.css/animate.css";

const Layout = (props) => (
    <div className={'page-template-' + props.page}>
        { props.showExitup ? <Exitup firstRenderTimestamp={new Date()} /> : '' }
        {props.showMenu ? <Menu ctaProductName={props.ctaProductName} /> : '' }
        <ParallaxProvider>
            <main>{props.children}</main>
        </ParallaxProvider>
        { !props.showFooter ? '' : <Footer /> }
    </div>
);  

Layout.propTypes = {
    page: PropTypes.string.isRequired,
    ctaProductName: PropTypes.string,
    children: PropTypes.node.isRequired
};

Layout.defaultProps = {
    ctaProductName: undefined,
    showFooter: true,
    showExitup: true,
};

export default Layout;
