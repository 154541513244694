import { graphql } from "gatsby";
import trainingsIcon from "../images/icons/trainings.svg";
import calendarIcon from "../images/icons/calendar.svg";

export const TRAINING_BY_ANN_COM = "trainingbyann.com";
export const APP_STORE_URL =
    "https://itunes.apple.com/pl/app/diet-by-ann-dieta-i-odchudzanie-anna-lewandowska/id1142970408";
export const GOOGLE_PLAY_URL =
    "https://play.google.com/store/apps/details?id=com.apzumi.mobile.dietlabs.dietByAnn&hl=pl";

export const TRAINING_PROGRAMS = 35;
export const TRAINING_PROGRAMS_ICON = trainingsIcon;
export const CALENDAR_ICON = calendarIcon;
export const TRAINING_DAYS = 1600;
export const DIET_COUNT = 4;
export const USER_COUNT = '800 000';
export const DVD_COUNT_GREATER_THAN = 600;

export const fluidImage1920 = graphql`
    fragment fluidImage1920 on File {
        childImageSharp {
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`;

export const fluidImage960 = graphql`
    fragment fluidImage960 on File {
        childImageSharp {
            fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`;

export const fluidImage480 = graphql`
    fragment fluidImage480 on File {
        childImageSharp {
            fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`;

export const fixedImage176 = graphql`
    fragment fixedImage176 on File {
        childImageSharp {
            fixed(width: 176) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`;

export const fixedImage100 = graphql`
    fragment fixedImage100 on File {
        childImageSharp {
            fixed(width: 100) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`;

export const fixedImage62 = graphql`
    fragment fixedImage62 on File {
        childImageSharp {
            fixed(width: 62) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`;

