import React, { Component } from 'react';
import { string } from 'prop-types';
import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import logo from "../images/hpba_signet_name.png";
import menuAuthorized from "../constants/menu/authorized";
import menuUnauthorized from "../constants/menu/unauthorized";

class Menu extends Component {
    static propTypes = {
        ctaProductName: string,
        ctaUrl: string,
    };

    static defaultProps = {
        ctaProductName: 'dietę i treningi',
    };

    constructor(props) {
        super(props);
        this.toggleMainMenu = this.toggleMainMenu.bind(this);
        this.fakeToggle = this.fakeToggle.bind(this);

        this.state = {
            isOpenMainMenu: false,
            menuItems: menuUnauthorized,
        };

        if (global.localStorage && global.localStorage.getItem('token')) {
            this.mainNavigationLogout = menuAuthorized;
        } else {
            this.mainNavigationLogout = menuUnauthorized;
        }
    }
   
    toggleMainMenu() {
        this.setState(prevState => ({
            isOpenMainMenu: !prevState.isOpenMainMenu,
        }));
    }

    fakeToggle() {}

    render() {
        return (
            <section id="main-menu">
                <Navbar
                    expand="xl"
                    fixed="top"
                    className={
                        this.state.isOpenMainMenu
                            ? 'navbar-open logout'
                            : 'logout'
                    }
                >
                    <Container>
                        <a
                            className="navbar-brand"
                            href="/"
                        >
                            <img src={logo} className="img-fluid d-none d-md-block" alt="Diet & Training by Ann" />
                            {
                                this.state.isOpenMainMenu
                                ? <img src={logo} className="img-fluid d-md-none" alt="Diet & Training by Ann" />
                                : <img src={logo} className="img-fluid d-md-none" alt="Diet & Training by Ann" />
                            }
                        </a>

                        <NavbarToggler
                            onClick={this.toggleMainMenu}
                            className="d-md-none"
                        >
                            Menu
                            <span className="dropdown-arrow ml-2">
                                <svg
                                    aria-hidden="true"
                                    data-prefix="fas"
                                    data-icon="chevron-down"
                                    className="svg-inline"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                                    />
                                </svg>
                            </span>
                            
                        </NavbarToggler>

                        <Collapse
                            isOpen={this.state.isOpenMainMenu}
                            navbar
                            className="d-md-none"
                        >
                            <Nav navbar>
                                {this.mainNavigationLogout.map((node, k) => (
                                    <NavItem
                                        key={k}
                                    >
                                        {
                                            node.newWindow ? (
                                                <a
                                                    href={node.path}
                                                    className="nav-link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {node.label}
                                                </a>
                                            ) : (
                                                <a
                                                    className="nav-link"
                                                    href={node.path}
                                                >
                                                    {node.label}
                                                </a>
                                            )
                                        }
                                        
                                    </NavItem>
                                ))}
                            </Nav>
                        </Collapse>

                        <div className="d-none d-md-block">
                            <Dropdown
                                isOpen={this.state.isOpenMainMenu}
                                toggle={this.fakeToggle}
                            >
                                <DropdownToggle
                                    tag="span"
                                    className="dropdown-toggler"
                                    onClick={this.toggleMainMenu}
                                >
                                    Menu
                                    <span className="dropdown-arrow ml-2">
                                        <svg
                                            aria-hidden="true"
                                            data-prefix="fas"
                                            data-icon="chevron-down"
                                            className="svg-inline"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                                            />
                                        </svg>
                                    </span>                                    
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {this.mainNavigationLogout.map((node, k) => (
                                        <DropdownItem
                                            key={k}
                                            tag="div"
                                        >
                                            {
                                                node.newWindow ? (
                                                    <a
                                                        href={node.path}
                                                        className="nav-link"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {node.label}
                                                    </a>
                                                ) : (
                                                    <a
                                                        className="nav-link"
                                                        href={node.path}
                                                    >
                                                        {node.label}
                                                    </a>
                                                )
                                            }
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Container>
                </Navbar>
                <div className="push push-top" />
            </section>
        );
    }
}

export default Menu;
