export default [
    {
        label: "Workouts",
        path: "/treningi"
    },
    {
        label: "Knowledge",
        path: "/blog/all"
    },
    {
        label: "Blog",
        path: "https://healthyplanbyann.com",
        newWindow: true
    },
    {
        label: "Contact",
        path: "/contact"
    },
    {
        label: 'Go to Day Plan',
        path: '/day-plan',
    }
];
