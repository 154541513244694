import React from 'react';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

export class ExitupComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false };
        this.toggle = this.toggle.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    toggle() {
        if (global && global.localStorage) {
            localStorage.setItem('exitup', !this.state.modal);
        }

        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    showExitup() {
        this.setState({ modal: true });
    }

    handleMouseOut(event) {
        try {
            let exitup = true;

            // check local storage
            if (
                window &&
                window.localStorage &&
                window.localStorage.getItem('exitup')
            ) {
                exitup = window.localStorage.getItem('exitup') === 'true';
            }

            // check resolution
            const w =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            if (w < 576) {
                if (this.state.modal) {
                    this.setState({ modal: false });
                }
                exitup = false;
            }

            if (exitup) {
                const MIN_DELAY = 3000;
                const from = event.relatedTarget || event.toElement;

                if (!from || from.nodeName === 'HTML') {
                    if (
                        new Date() - this.props.firstRenderTimestamp >
                        MIN_DELAY
                    ) {
                        this.showExitup();
                    }
                }
            }
        } catch (error) {
            this.props.handleCriticalError(error);
        }
    }

    componentDidMount() {
        document.addEventListener('mouseout', this.handleMouseOut);
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    centered
                    size="lg"
                    className="d-none d-sm-flex"
                >
                    <ModalBody>
                        <Container fluid>
                            <Row className="align-items-center">
                                <Col sm="6" className="pl-0">

                                    <StaticQuery
                                        query={graphql`
                                            query {
                                                mind: file(relativePath: { eq: "homepage/mind.png" }) {
                                                    ...fluidImage480
                                                }
                                            }
                                        `}

                                        render={data => <Img fluid={data.mind.childImageSharp.fluid} />}
                                    />

                                </Col>
                                <Col sm="6" className="py-3">
                                    <h3>Sprawdź, ile wiesz na temat zdrowego odżywiania - wypełnij QUIZ!</h3>
                                    <br />
                                    <p>W nagrodę otrzymasz dania z mocą autorstwa Ani Lewandowskiej.</p>

                                    <a
                                        className="mt-3 btn btn-blue"
                                        onClick={this.toggle}
                                        href="/quiz"
                                        data-test="exitup-button"
                                    >
                                        Rozpocznij quiz
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                        <button
                            type="button"
                            className="close"
                            onClick={this.toggle}
                        >
                            &times;
                        </button>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ExitupComponent;
